import React from 'react';
import {ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';


export const ReimbursementGraph = ({total, completed, reimbursed}) => {
    const incomplete = total - completed;
    const data = [
        {
            name: "Completed",
            value: completed,
            colour: "#133b80",
        },
        {
            name: "Incomplete",
            value: incomplete,
            colour: "#d9d9d9",
        },
    ];
    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Pie data={data} dataKey="value" nameKey="name" startAngle={90} endAngle={-270} innerRadius={70} outerRadius={100} fill="#8884d8">
                    {data.map((entry, index) => {
                       return  <Cell key={`cell-${index}`} fill={entry.colour} />
                    })}
                    <Label value={`$${reimbursed}`} position="centerBottom"  className='font-bold' fontSize='22px' />
                    <Label value={`${completed} of ${total}`} position="centerTop" className='font-bold translate-y-2' fontSize='14px' />

                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}
