import { Switch } from "@headlessui/react";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useController } from "react-hook-form";

const Toggle = ({ label, value, checked, onChange, bordered }) => {
	return (
		<Switch.Group
			key={value}
			as="div"
			className={
				"toggle flex items-center gap-3" +
				(bordered ? " b py-6 border-t border-grey" : " py-2")
			}
		>
			<Switch value={value} onChange={onChange} checked={checked}>
				{({ checked }) => (
					/* Use the `checked` state to conditionally style the button. */
					<span
						className={`${
							checked ? "bg-blue-600" : "bg-gray-200"
						} relative inline-flex h-6 w-11 items-center rounded-full`}
					>
						<span className="sr-only">{label}</span>
						<span
							className={`${
								checked ? "translate-x-6" : "translate-x-1"
							} inline-block h-4 w-4 transform rounded-full bg-white transition`}
						/>
					</span>
				)}
			</Switch>
			<Switch.Label>{label}</Switch.Label>
		</Switch.Group>
	);
};

export const ToggleGroup = ({
	label,
	name,
	choices,
	required,
	selectAllEnabled = false,
	bordered = false,
	type = "checkbox",
}) => {
	const { field, ref } = useController({
		name,
		rules: {
			required: { value: required, message: "Please select an option" },
		},
		setValueAs: (v) => v.split(","),
	});
	const [value, setValue] = React.useState(field.value || []);

	const handleChange = (checked, radioValue) => {
		const valueCopy = [...value];
		if (type == "checkbox") {
			if (checked) {
				valueCopy.push(radioValue);
			} else {
				valueCopy.splice(valueCopy.indexOf(radioValue), 1);
			}
			field.onChange(valueCopy);
			setValue(valueCopy);
		} else {
			field.onChange(radioValue);
			setValue([radioValue]);
		}
	};

	const selectAll = (checked) => {
		if (checked) {
			field.onChange(choices.map((choice) => choice[0]));
			setValue(choices.map((choice) => choice[0]));
		} else {
			field.onChange([]);
			setValue([]);
		}
	};

	return (
		<div ref={ref} className="flex flex-col gap-3">
			<fieldset
				className={
					"relative rounded-lg" + (bordered ? " border-navy px-5 border-2" : "")
				}
			>
				{label && (
					<legend className="absolute px-1.5 left-8 -top-3 bg-white z-10">
						{label}
					</legend>
				)}
				{selectAllEnabled && (
					<Toggle
						label="Select All"
						value="all"
						checked={value.length == choices.length}
						onChange={selectAll}
						bordered={bordered}
					/>
				)}
				{choices.map((choice) => (
					<Toggle
						label={choice[1]}
						value={choice[0]}
						key={choice[0]}
						checked={value.includes(choice[0])}
						onChange={(v) => handleChange(v, choice[0])}
						bordered={bordered}
					/>
				))}
			</fieldset>
			<ErrorMessage
				name={name}
				render={({ message }) => <span className="text-red">{message}</span>}
			/>
		</div>
	);
};
