import { Listbox } from "@headlessui/react";
import React from "react";
import { useController } from "react-hook-form";
import ArrowDown from "../../../svgs/arrow-down.svg";
import { FieldContainer } from "./FieldContainer";

export const Select = ({
	choices,
	value,
	name,
	id,
	label,
	help_text,
	required,
	disabled,
}) => {
	const { field, ref } = useController({
		name,
		defaultValue: value,
		rules: {
			required: { value: required, message: "Please select an option" },
		},
	});

	return (
		<FieldContainer
			id={id}
			name={name}
			label={label}
			help_text={help_text}
			required={required}
			disabled={disabled}
			ref={ref}
		>
			<Listbox
				defaultValue={field.value}
				onChange={field.onChange}
				as="div"
				className="relative"
				disabled={disabled}
			>
				<Listbox.Button
					as="div"
					className={
						"input-container sm:text-base ui-open:border-blue w-full text-sm" +
						(field.value ? " border-black" : "") + (disabled ? " text-grey" : "")
					}
				>
					{field.value
						? choices.filter((c) => c[0] == field.value)[0][1]
						: "Please select"}
					<ArrowDown className="ui-open:text-blue ui-disabled:text-grey ui-open:rotate-180 w-3 ml-auto transition-transform fill-current" />
				</Listbox.Button>
				<Listbox.Options as="div" className="options-container top-5">
					<div style={{ height: "35px" }}></div>
					<div className="options-container-inner">
						{choices.map((choice) => (
							<Listbox.Option
								key={choice[0]}
								value={choice[0]}
								className="input-item"
								as="div"
							>
								{choice[1]}
							</Listbox.Option>
						))}
					</div>
				</Listbox.Options>
			</Listbox>
		</FieldContainer>
	);
};
