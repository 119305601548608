import React from "react";
import chroma from "chroma-js"

import { ResponsiveContainer, BarChart, Bar, Label, LabelList, XAxis, CartesianGrid, Tooltip, Cell, YAxis } from "recharts";


export const TopAttendeesGraph = ({ data }) => {
    const colors = chroma.scale(["#081c3f", "#a7c7ff"]).colors(data.length);
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{bottom: 25, top: 10}} layout="vertical">
                <CartesianGrid stroke="rgba(128, 176, 255, 0.3)"  horizontal={false} />
                <Tooltip />
                <YAxis dataKey='course' type='category' hide={true} />
                <XAxis
                    dataKey="count"
                    axisLine={false}
                    tickLine={false}
                    type="number"
                    interval={"preserveStartEnd"}
                    tickMargin={5}
                >
                    <Label
                        value="Number of Attendees"
                        position={"bottom"}
                        offset={10}
                    />
                </XAxis>
                <Bar dataKey="count" name="Total attendees" barSize={16}>
                    <LabelList
                        dataKey="course"
                        position="insideBottomLeft"
                        fill="black"
                        offset={0}
                        dy={15}
                        width={400}
                        style={{ fontSize: "14px"}}
                    />
                    {data.map((_, index) => <Cell key={`cell-${index}`} fill={colors[index]} />)}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
