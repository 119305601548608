import React from "react";
import { useFormContext } from "react-hook-form";
import { FieldContainer } from "./FieldContainer";

export const InputField = ({
	name,
	id,
	value,
	label,
	help_text,
	required,
	disabled,
	errors,
	type,
	extra = {},
	validation = {},
}) => {
	const { register, watch } = useFormContext();
	const watchValue = watch(name);

	return (
		<FieldContainer
			id={id}
			label={label}
			help_text={help_text}
			disabled={disabled}
			name={name}
			required={required}
		>
			<input
				{...register(name, {
					required: { value: required, message: "This field is required" },
					...validation,
				})}
				id={id}
				name={name}
				type={type}
				defaultValue={value}
				disabled={disabled}
				{...extra}
				className={
					"focus:border-blue peer w-full p-3 pl-5 border-2 rounded-full outline-none" +
					(watchValue ? " border-black" : " border-grey")
				}
			/>
		</FieldContainer>
	);
};
