import React from "react";
import QuestionMark from "../../../svgs/question_mark.svg";

export const ToolTip = ({ children }) => {
	return (
		<div className="w-min relative z-10 inline-flex items-center gap-1 normal-case">
			<div className="question-mark-icon peer">
				{" "}
				<QuestionMark />
			</div>
			<div className="text-base pointer-events-none peer-hover:opacity-100 w-[30ch] left-full bottom-3/4 border-grey absolute p-1 transition-opacity bg-white border rounded-md opacity-0 z-50">
				{children}
			</div>
		</div>
	);
};
