import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { ToolTip } from "./ToolTip";

export const Checkbox = ({
	name,
	id,
	value,
	label,
	help_text,
	required,
	disabled,
	errors,
}) => {
	const { register, setValue } = useFormContext();
	if (typeof value === "string") {
		value = value === "true";
	}
	if (!value) {
		value = false;
	}
	const [checked, setChecked] = React.useState(value);

	React.useEffect(() => {
		setChecked(value);
		setValue(name, value);
	}, [value]);

	return (
		<div className="flex flex-col gap-2">
			<label htmlFor={id} className="flex items-start gap-2">
				<input
					{...register(name, {
						required: {
							value: required,
							message: "Check this box to continue",
						},
					})}
					type="checkbox"
					id={id}
					name={name}
					disabled={disabled}
					checked={checked}
					onChange={(e) => {
						setChecked(e.target.checked);
						setValue(name, e.target.checked);
					}}
					className="flex-shrink-0 w-4 h-4 accent-blue peer mt-[2px]"
				/>
				<span className="ml-2 peer-disabled:text-grey" dangerouslySetInnerHTML={{__html: label}} />
				{help_text && <ToolTip>{help_text}</ToolTip>}
			</label>
			<ErrorMessage
				name={name}
				render={({ message }) => <span className="text-red">{message}</span>}
			/>
		</div>
	);
};
