import React from "react";
import { createRoot } from "react-dom/client";
import { ReimbursementGraph } from "./ReimbursementGraph";
import { TopAttendeesGraph } from "./TopAttendeesGraph";
import { TopFundedGraph } from "./TopFundedGraph";

class ReactAdapter {
	mount(domContainer) {
		const root = createRoot(domContainer);
		root.render(this.render());
	}
	render() {
		throw new Error("Not implemented");
	}
}


class ReimbursementsGraphAdapter extends ReactAdapter {
	constructor(total, completed, reimbursed) {
		super();
		this.total = total;
		this.completed = completed;
		this.reimbursed = reimbursed;
	}

	render() {
		return (
			<ReimbursementGraph
				total={this.total}
				completed={this.completed}
				reimbursed={this.reimbursed}
			/>
		);
	}
}

window.telepath.register(
	"graphs.ReimbursementsGraph",
	ReimbursementsGraphAdapter
);

class TopAttendeesGraphAdapter extends ReactAdapter {
	constructor(data) {
		super();
		this.data = data;
	}

	render() {
		return <TopAttendeesGraph data={this.data} />;
	}
}

window.telepath.register("graphs.TopAttendeesGraph", TopAttendeesGraphAdapter);

class TopFundedGraphAdapter extends ReactAdapter {
	constructor(data) {
		super();
		this.data = data;
	}

	render() {
		return <TopFundedGraph data={this.data} />;
	}
}

window.telepath.register("graphs.TopFundedGraph", TopFundedGraphAdapter);
