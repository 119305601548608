import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ToolTip } from "./ToolTip";

export const RadioButtons = ({
	name,
	value,
    choices,
	label,
	help_text,
	required,
	disabled,
	errors,
}) => {
	const { register, watch } = useFormContext();

	return (
		<div>
            <div>
                {label}
                {help_text && (<>&nbsp;<ToolTip>{help_text}</ToolTip> </>)}
            </div>
            <div className='group flex gap-6 mt-2'>
            {choices.map((choice) => (
                <div key={choice[0]} className="flex items-center gap-2">
                    <input
                        {...register(name, {
                            required: { value: required, message: "This field is required" },
                        })}
                        id={`${name}_${choice[0]}`}
                        name={name}
                        type="radio"
                        value={choice[0]}
                        defaultChecked={value == choice[0]}
                        disabled={disabled}
                        className="border-grey group-hover:border-blue focus:border-blue bg-blue checked:border-blue w-4 h-4 border-2 rounded-full cursor-pointer"
                    />
                    <label htmlFor={`${name}_${choice[0]}`}>{choice[1]}</label>
                </div>
            ))}
            </div>
            <ErrorMessage
				name={name}
				render={({ message }) => <span className="text-red">{message}</span>}
			/>
        </div>
    );
}
