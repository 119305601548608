import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../svgs/close.svg";

export const Modal = ({ title, children, isOpen, closeModal }) => {
	return (
		<Transition show={isOpen} appear as={Fragment}>
			<Dialog onClose={closeModal} className="fixed inset-0 z-50">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div
						className="bg-navy bg-opacity-80 fixed inset-0"
						aria-hidden="true"
					/>
				</Transition.Child>
				<div className="fixed inset-0 flex items-center justify-center min-h-full">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-500"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel className="border-blue p-8 bg-white border-4 rounded-md">
							<div className="flex-col-5 w-60 md:w-96">
								<div
									className="-right-4 -top-4 bg-blue absolute p-2 text-white rounded-full cursor-pointer"
									onClick={closeModal}
								>
									<CloseIcon className="w-5 h-5" />
								</div>
								<Dialog.Title as="h2" className="text-xl">
									{title}
								</Dialog.Title>
								{children}
								<button className="app-page-link w-min" onClick={closeModal}>
									Ok
								</button>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};
